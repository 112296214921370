export const IS_PRODUCTION = process.env.GATSBY_ACTIVE_ENV === "production"

export const WP_SEARCH_CATEGORIES = {
  // POSTS: {
  //   title: "Posts",
  //   slug: "post",
  // },
  PAGES: {
    title: "Pages",
    wpSlug: "page",
    pageSlug: "",
  },
  INSIGHTS: {
    title: "Insights",
    wpSlug: "insight",
    pageSlug: "insight",
  },
  CASE_STUDIES: {
    title: "Case Studies",
    wpSlug: "casestudies",
    pageSlug: "case-study",
  },
}
